import { Injectable } from '@angular/core';
import {ToastController} from '@ionic/angular';
import {TranslateService} from "@ngx-translate/core";
import {HttpErrorResponse} from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  public static TOAST_STATUS = {
    danger: 'danger',
    warning: 'warning',
    success: 'success',
  };

  private logErrors = true;
  private logDebug = true;

  constructor(
      private toastService: ToastController,
      private translate: TranslateService,
      private router: Router,
      ) { }

  public async showToast(toastMessageTranslateKey: string, color: string, duration: number = 15000): Promise<void> {
    this.translate.get(toastMessageTranslateKey).toPromise()
        .then(async (res: string) => {
          if (duration < 1000) {
            duration = 1000;
          }
          const toast = await this.toastService.create({
            message: res,
            color,
            buttons: [
              {
                role: 'close',
                text: 'Schließen'
              },
            ],
            duration,
          });
          await toast.present();
        });
  }

  public debug(...params: any[]): void{
    if(this.logDebug){
      console.log(...params);
    }
  }

  public async logError(...params: any[]): Promise<void> {
    if (this.logErrors) {
      for (const x of params) {
        console.log(x);
        if (x.error != undefined && x.error instanceof Blob) {
          await x.error.text().then(z => {
            if(z != undefined && z.length > 0){
              try{
                console.log('parsed error blob:',JSON.parse(z));
              }catch (e){

              }
            }
          });
        }
      }
    }
  }

  public formatDate(timestamp: number): string{
    let date = new Date(timestamp);
    if(date == undefined) return 'N/A';
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let year = date.getFullYear();
    return `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${year}`;
  }

  public async checkMemory(memoryUsed, memoryTotal, alertController): Promise<void> {
    let message = '';
    let yes = '';
    let no = '';
    await this.translate.get('MEMORY.LOW_ON_MEMORY').toPromise()
      .then((res: string) => {
        message = res;
      });
    await this.translate.get('BUTTON.YES').toPromise()
      .then((res: string) => {
        yes = res;
      });
    await this.translate.get('BUTTON.NO').toPromise()
      .then((res: string) => {
        no = res;
      });
    if (memoryUsed >= memoryTotal - 100) {
      const alert = await alertController.create({
        cssClass: 'my-custom-class',
        header: '',
        message: message,
        buttons: [
          {
            text: yes,
            cssClass: 'yes',
            handler: async () => {
              this.router.navigate(['profile'], {state: {selectedTab: 2}});
              // alert.dismiss();
            },
          },
          {
            text: no,
            cssClass: 'no',
            handler: () => {
              alert.dismiss();
            },
          },
        ],
      });

      await alert.present();
    }
  }
}
