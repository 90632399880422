import {AfterViewInit, Component} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {SettingsService} from './services/settings.service';
import {SpinnerService} from './services/spinner.service';
import {AlertController} from '@ionic/angular';
import {AuthService} from './services/auth.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewInit{

  public showSidebar: boolean = false;
  public showProfile: boolean = false;
  public showLogout: boolean = false;
  private history: string[] = [];
  private hideProfileUrls = [
    '/login',
    '/register',
    '/folder-editor',
    '/page-editor',
    '/new-editor',
    '/register-activation',
    '/forgot-password'
  ];
  private hideSidebarUrls = [
    '/folder-editor',
    '/page-editor',
    '/new-editor',
    '/register-activation'
  ];
  private hideLogoutUrls = [
    '/login',
    '/register',
    '/register-activation'
  ]
  public menus = [
    {
      urls: [
        '/login',
        '/register'
      ],
      items: [
        {
          title: 'Login',
          href: '/login'
        },
        {
          title: 'Registrieren',
          href: '/register'
        },
        {
          title: 'Hilfe',
          href: 'https://www.porttoolio.de/',
          target: '_blank'
        }
      ]
    },

    {
      urls: [
        '/home',
        '/group-selection',
        '/child-selection'
      ],
      items: [
        {
          title: 'Home',
          href: '/home'
        },
        {
          title: 'Portfolio gestalten',
          href: '#'
        },
        {
          title: 'Kita Gruppen',
          href: '/group-selection'
        },
        {
          title: 'Bestellungen',
          href: '#'
        },
        {
          title: 'Zurück',
          href: '#back'
        },
      ]
    }
  ];
  public maintenance: boolean = false;
  public spinnerActive: boolean = false;
  public spinnerText: string = '';
  public hasProgress: boolean = false;
  public animatedDots: string = '';
  public maxProgress: number = 1;
  public currentProgress: number = 0;
  public progressCalc: number = 0;
  public sideBarActive: boolean = false;

  private deviceCheckActive: boolean = false;
  private didDeviceSizeRemainSmall: boolean = false;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private settingsService: SettingsService,
    private spinnerService: SpinnerService,
    private alertController: AlertController,
    private loginService: AuthService,
    private location: Location
  ) {
    translate.addLangs(['de', 'en']);
    translate.setDefaultLang('de');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/de|en/) ? browserLang : 'en');
    this.checkRoute();
    this.maintenance = this.settingsService.maintenanceMode;
    this.settingsService.maintenanceEvent.subscribe(() => {
      this.maintenance = this.settingsService.maintenanceMode;
    });
    this.spinnerService.spinnerChangeEvent.subscribe((val: boolean) => {
      if (typeof val == 'boolean') {
        this.spinnerText = this.spinnerService.spinnerText;
        this.hasProgress = this.spinnerService.hasProgress;
        this.maxProgress = this.spinnerService.maxProgress;
        this.currentProgress = 0;
        this.spinnerActive = val;
      }
    });
    this.spinnerService.spinnerProgressUpdate.subscribe((increase: number) => {
      if(typeof increase == 'number'){
        this.currentProgress += 1;
        if(this.maxProgress != 0){
          this.progressCalc = (this.currentProgress / this.maxProgress) * 100;
        }
      }
    });

    window.onresize = (() => {
      setTimeout(async () => {
        this.deviceCheck();
      }, 100);
    });

    this.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    })
  }


  private async deviceCheck(): Promise<void> {
    if (window.innerWidth > 1000){
      this.didDeviceSizeRemainSmall = false;
    }

    if (window.innerWidth < 1000 && !this.deviceCheckActive && !this.didDeviceSizeRemainSmall) {
      this.didDeviceSizeRemainSmall = true;
      this.deviceCheckActive = true;
      let button = 'ok';
      let message = 'This application is optimized for devices with a viewport width of 1000px or more. You can use it at lower resolutions but not all features might work as intended.';
      await this.translate.get('TOASTS.DEVICE_RESOLUTION_TOO_SMALL').toPromise()
        .then((res: string) => {
          message = res;
        });
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: '',
        message: message,
        buttons: [
          {
            text: button,
            cssClass: 'yes',
            handler: () => {
              alert.dismiss();
            },
          },
        ],
      });
      alert.onWillDismiss().then(() => {
        this.deviceCheckActive = false;
      });

      await alert.present();

    }
  }

  public profile(): void{
    this.router.navigate(['/profile']);
  }

  public checkRoute(): void{
    this.router.events.subscribe((e) => {
      if(e instanceof NavigationEnd){
        if(this.router.url == '/'){
          this.showSidebar = false;
          this.showProfile = false;
        }else{
          this.showProfile = this.hideProfileUrls.find(x => this.router.url.startsWith(x)) == undefined;
          this.showSidebar = this.hideSidebarUrls.find(x => this.router.url.startsWith(x)) == undefined;
          this.showLogout = this.hideLogoutUrls.find(x => this.router.url.startsWith(x)) == undefined;
        }
      }
    });
  }

  public getMenuItems() {
    for(let m of this.menus){
      let hit = m.urls.find(x => this.router.url.startsWith(x));
      if(hit != undefined){
        let items = m.items;
        for(let item of items){
          item['active'] = this.router.url == item.href;
        }
        return items;
      }
    }
    return [];
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.deviceCheck();
    });
  }

  public logout(): void {
    this.loginService.logout();
  }

  public back(): void {
    this.history.pop();
    if(this.history.length > 0){
      this.location.back();
    }else{
      this.router.navigate(['/home']);
    }
  }
}
